import { PureComponent, ReactNode } from 'react'

import styled from '@emotion/styled'

const SectionTitle = styled.h2`
  color: ${(p) => p.color || '#373838'};
  font-size: 2rem;
  margin: 0;
  font-weight: 800;
  font-family: 'Open Sans', Helvetica;
`

type PropTypes = {
  children: ReactNode
  componentStyle: Record<string, unknown>
  className?: string
  color?: string
  e2eId?: string
}
export default class SectionTitleComponent extends PureComponent<PropTypes> {
  static defaultProps = {
    componentStyle: {}
  }

  render() {
    const { children, componentStyle, className, color, e2eId } = this.props

    return (
      <SectionTitle data-e2eid={e2eId} className={className} style={{ ...componentStyle }} color={color}>
        {children}
      </SectionTitle>
    )
  }
}
