import { PureComponent, ReactNode } from 'react'

import styled from '@emotion/styled'

import PropTypes from 'prop-types'

const Title = styled.h1`
  color: ${(p) => p.color || '#373838'};
  font-size: 3.2rem;
  line-height: 3.4rem;
  margin: 0;
  font-weight: 800;
  font-family: 'Open Sans', Helvetica;
`

type PropTypes = {
  children: ReactNode
  componentStyle: Record<string, unknown>
  className?: string
  color?: string
  e2eId?: string
}
export default class TitleComponent extends PureComponent<PropTypes> {
  static defaultProps = {
    componentStyle: {}
  }

  render() {
    const { children, componentStyle, className, color, e2eId } = this.props

    return (
      <Title data-e2eid={e2eId} className={className} style={{ ...componentStyle }} color={color}>
        {children}
      </Title>
    )
  }
}
