import { PureComponent, ReactNode } from 'react'

import styled from '@emotion/styled'

const Text = styled.p`
  color: ${(p) => p.color || '#373838'};
  font-size: 1.4rem;
  font-family: 'Proxima', 'Open sans', Helvetica;
  letter-spacing: 0.03em;
  margin: 0;
`

type PropTypes = {
  children: ReactNode
  componentStyle: Record<string, unknown>
  className: string
  color: string
  e2eId?: string
}
export default class TextComponent extends PureComponent<PropTypes> {
  static defaultProps = {
    componentStyle: {},
    className: '',
    color: ''
  }

  render() {
    const { children, componentStyle, className, color, e2eId } = this.props

    return (
      <Text className={className} style={{ ...componentStyle }} color={color} data-e2eid={e2eId || null}>
        {children}
      </Text>
    )
  }
}
